import { dynamicsObject } from "@/interfaces"
import { Modal } from "@/interfaces/modal/modal.dto"
import { USER_SOURCE_DROP } from "@/utils/enums"

export const owner = (data: dynamicsObject): Array<Modal.Module> => {
  return [
    {
      id: 'admin-channel-list',
      data: {
        _id: data?._id,
        channels: [],
      },
      inputs: [
        { required: false, name: '', placeholder: '', id: 'channels', grid: '1 / 13', type: 'channel-list', drop: false, showDrop: false, drop_data: [], show: true, disabled: true },
      ]
    },
    {
      id: 'admin-edit',
      data: {
        _id: data?._id,
        name: data?.name,
        source: data?.source,
        country: data?.country,
        device: data?.device,
        balance: data?.balance,
      },
      inputs: [
        { required: false, name: 'Имя', placeholder: '', id: 'name', grid: '1 / 13', type: 'text', drop: false, showDrop: false, drop_data: [], show: true, disabled: true },
        { required: false, name: 'Источник', placeholder: 'Введи источник', id: 'source', grid: '1 / 13', type: 'text', drop: true, showDrop: false, drop_data: USER_SOURCE_DROP, show: true },
        { required: false, name: 'Страна', placeholder: 'Введи страну', id: 'country', grid: '1 / 7', type: 'text', drop: false, showDrop: false, drop_data: [], show: true },
        { required: false, name: 'Устройство', placeholder: 'Введи устройство', id: 'device', grid: '7 / 13', type: 'text', drop: false, showDrop: false, drop_data: [], show: true },
        { required: false, name: 'Баланс', placeholder: 'Введи баланс пользователя', id: 'balance', grid: '1 / 13', type: 'text', mask: '######', drop: false, showDrop: false, drop_data: [], show: true },
      ]
    },
    {
      id: 'admin-delete',
      data: {
        _id: data?._id,
        name: data?.name,
      },
      inputs: []
    },
  ]
}