import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1840d261"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex items-center" }
const _hoisted_2 = { class: "avatar" }
const _hoisted_3 = { class: "container" }
const _hoisted_4 = { class: "message" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_botpay_icon = _resolveComponent("botpay-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_botpay_icon)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("span", {
          class: _normalizeClass(["fz13 fw300", { fw500: _ctx.type === 'error' }])
        }, _toDisplayString(_ctx.message), 3)
      ])
    ])
  ]))
}