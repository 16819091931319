
import { defineComponent, PropType } from "vue-demi";
import { CalendarDates } from "@/interfaces/common/calendar";
import { getYearList } from "@/utils/date/calendar";
import { getDateNullTime } from "@/utils/date";

export default defineComponent({
  emits: ['selectYear'],
  name: 'ContainerYears',
  props: {
    dates: {
      type: Object as PropType<CalendarDates>,
      required: true
    },
    isSingleType: {
      type: Boolean,
      required: true
    },
    currentMonth: {
      type: Date,
      required: true
    }
  },
  computed: {
    years() {
      return getYearList(this.currentMonth)
    }
  },
  methods: {
    isSeletedYear(date: Date) {
      return Number(date) === Number(getDateNullTime(this.dates.startedAt as Date, 'year')) || 
             Number(date) === Number(getDateNullTime(this.dates.endedAt as Date, 'year'))
    },
    isBetweenYear(date: Date) {
      if (!this.dates.startedAt || !this.dates.endedAt) return false;
      if (this.dates.startedAt.getFullYear() < date.getFullYear() && this.dates.endedAt.getFullYear() > date.getFullYear()) return true;
      return false;
    },
  }
})
