import { Modal } from "@/interfaces/modal/modal.dto";

export const advertising: Array<Modal.Content> = [
  {
    id: 'advertising-create',
    title: 'Добавить рекламного партнера',
    action: 'add',
    request: '/api/advertising',
    method: 'post',
    cancelName: 'Отмена',
    submitName: 'Добавить',
    emit: 'table-action',
  },
  {
    id: 'advertising-edit',
    title: 'Редактировать рекламного партнера',
    action: 'edit',
    request: '/api/advertising',
    method: 'patch',
    cancelName: 'Отмена',
    submitName: 'Сохранить',
    emit: 'table-action',
  },
  {
    id: 'advertising-delete',
    title: '',
    action: 'delete',
    request: '/api/advertising',
    method: 'delete',
    message: 'удалить рекламного партнера',
    cancelName: 'Отмена',
    submitName: 'Удалить',
    emit: 'table-action',
  },
];