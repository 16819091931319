
import { defineComponent, PropType } from "vue-demi";
import { User } from "@/interfaces/user/user.dto";
import { GearIcon, LogoutIcon } from '@/plugins/icons';
import IconButton from '@/components/common/button/IconButton.vue';

export default defineComponent({
  name: 'HeaderProfile',
  props: {
    user: {
      type: Object as PropType<User.Dto>,
      required: true
    }
  },
  methods: {
    logoutAction() {
      this.$store.dispatch('logoutAction');
    }
  },
  components: {
    IconButton,
    LogoutIcon,
    GearIcon
  }
})

