import { Modal } from "@/interfaces/modal/modal.dto";

export const subscriber: Array<Modal.Content> = [
  {
    id: 'subscriber-extension',
    title: 'Продление подписки',
    action: 'edit',
    request: '/api/subscriber/extension',
    emit: 'table-action',
    method: 'patch',
    cancelName: 'Отмена',
    submitName: 'Продлить',
  },
  {
    id: 'subscriber-ban',
    title: 'Блокировка подписчика',
    action: 'edit',
    request: '/api/subscriber/ban',
    emit: 'table-action',
    method: 'patch',
    cancelName: 'Отмена',
    submitName: 'Заблокировать',
  },
  {
    id: 'subscriber-unban',
    title: 'Разблокировка подписчика',
    action: 'edit',
    request: '/api/subscriber/unban',
    emit: 'table-action',
    method: 'patch',
    cancelName: 'Отмена',
    submitName: 'Разблокировать',
  },
  {
    id: 'subscriber-referral-list',
    title: 'Список приглашенных',
    action: 'static',
    cancelName: 'Отмена',
    submitName: 'Ок',
  },
  {
    id: 'subscriber-quiz',
    title: 'Quiz подписчика',
    action: 'static',
    cancelName: 'Отмена',
    submitName: 'Ок',
  },
];