
import { defineComponent, PropType } from "vue-demi";
import { mask } from "vue-the-mask";
import { CalendarDates } from "@/interfaces/common/calendar";
import dateFilter from "@/filters/date.filter";

export default defineComponent({
  name: 'CalendarTime',
  data() {
    return {
      time: '00:00'
    }
  },
  props: {
    dates: {
      type: Object as PropType<CalendarDates>,
      required: true
    },
  },
  methods: {
    currentDayTime(date: Date | null) {
      if (!date) return '';
      if (this.time !== '00:00') this.setTime();
      return dateFilter(date, 'time');
    },
    updateTime(event: Event) {
      const input = event.target;
      if (!input) return;

      this.time = input.value;
      this.setTime();
    },
    setTime() {
      const time = this.time.split(':');
      this.dates.startedAt?.setHours(+time[0] || 0, +time[1] || 0);
    }
  },
  directives: {
    mask
  },
})
