import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, TransitionGroup as _TransitionGroup, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-69b83823"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "profile flex items-center" }
const _hoisted_2 = { class: "balances flex items-center" }
const _hoisted_3 = { class: "avatar" }
const _hoisted_4 = {
  key: 0,
  alt: "Аватар"
}
const _hoisted_5 = {
  key: 1,
  class: "flex-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_balance_botpay = _resolveComponent("balance-botpay")!
  const _component_balance = _resolveComponent("balance")!
  const _component_subscriber_icon = _resolveComponent("subscriber-icon")!
  const _component_down_arrow_icon = _resolveComponent("down-arrow-icon")!
  const _component_drop = _resolveComponent("drop")!
  const _component_mobile_drop = _resolveComponent("mobile-drop")!
  const _directive_lazy = _resolveDirective("lazy")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_balance_botpay, {
        botpay: _ctx.botpay,
        onModal: _cache[0] || (_cache[0] = ($event: any) => (_ctx.modal('botpay-withdrawal')))
      }, null, 8, ["botpay"]),
      _createVNode(_component_balance, {
        balance: _ctx.currentBalance,
        notification: _ctx.user.notificationAmount,
        onModal: _cache[1] || (_cache[1] = ($event: any) => (_ctx.modal('user-replenishment')))
      }, null, 8, ["balance", "notification"])
    ]),
    _createElementVNode("div", {
      class: "flex items-center cursor",
      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.toggleShow && _ctx.toggleShow(...args)))
    }, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.user.avatar)
          ? _withDirectives((_openBlock(), _createElementBlock("img", _hoisted_4, null, 512)), [
              [_directive_lazy, _ctx.currentImageSize(_ctx.user.avatar.src, 50, 50)]
            ])
          : (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(_component_subscriber_icon)
            ]))
      ]),
      (_ctx.isDesktop)
        ? (_openBlock(), _createBlock(_component_down_arrow_icon, { key: 0 }))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_TransitionGroup, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.show && _ctx.isDesktop)
          ? (_openBlock(), _createBlock(_component_drop, {
              key: 0,
              user: _ctx.user,
              onCancelDrop: _ctx.cancelDrop
            }, null, 8, ["user", "onCancelDrop"]))
          : _createCommentVNode("", true),
        (_ctx.show && !_ctx.isDesktop)
          ? (_openBlock(), _createBlock(_component_mobile_drop, {
              key: 1,
              user: _ctx.user,
              onCancelDrop: _ctx.cancelDrop
            }, null, 8, ["user", "onCancelDrop"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}