
import { defineComponent, PropType } from "vue-demi";
import { Calendar, CalendarDates } from '@/interfaces/common/calendar';
import { getTargetPosition } from "@/store/common/default/dates";
import { getDateNullTime } from "@/utils/date";
import ModalCalendarMain from './modules/CalendarMain.vue';
import ModalCalendarTime from './modules/CalendarTime.vue';

export default defineComponent({
  emits: ['actionTerms'],
  name: 'ModalCalendar',
  props: {
    calendar: {
      type: Object as PropType<Calendar>,
      required: true
    }
  },
  created() {
    const target = this.jq(this.calendar.options.target);
    this.calendar.options.position = getTargetPosition(target);
    
    if (!this.isSingleType) this.initialDates();
  },
  mounted() {
    document.addEventListener('keydown', this.keys);
    this.jq("body").bind("click", (e) => {
      if (this.jq(e.target).closest(this.calendar.options.target).length === 0 &&
          this.jq(e.target).closest(this.$refs.calendar as HTMLDivElement).length === 0) 
        this.$store.commit('destroyCalendar');
    });
    // Выход за границы
    const calendar = this.$refs.calendar as HTMLDivElement;
    if (calendar.offsetLeft + 260 >= window.innerWidth) {
      calendar.style.left = `${this.calendar.options.position.left - (calendar.offsetLeft + 305 - window.innerWidth)}px`;
    }
    
  },
  unmounted() {
    document.removeEventListener('keydown', this.keys);
    this.jq("body").unbind("click");
  },
  methods: {
    selectedDate(data: CalendarDates) {
      this.calendar.dates.startedAt = data.startedAt;
      if (this.isTimeType) return;
      if (this.isSingleType) return this.actionTerms();

      this.calendar.dates.endedAt = data.endedAt;
      if (data.startedAt && data.endedAt) this.actionTerms();
    },
    actionTerms() {
      if (!this.isSingleType) {
        const date = (this.calendar.dates.endedAt as Date);
        const dateBetweenTime = getDateNullTime(date, 'date', 'betweenTime');
        this.calendar.dates.endedAt = new Date(`${dateBetweenTime}T23:59`);
      }
      
      this.emitter.emit(this.calendar.options.id, this.calendar.dates);
      this.destroy();
    },
    initialDates() {
      if (!this.calendar.dates.startedAt) this.calendar.dates.startedAt = new Date();
      if (!this.calendar.dates.endedAt) {
        this.calendar.dates.endedAt = new Date(Number(new Date(this.calendar.dates.startedAt)) + 86400000);
      }
    },
    keys(ev: KeyboardEvent) {
      if (ev.key === 'Escape') this.destroy()
      if (ev.key === 'Enter') setTimeout(() => this.actionTerms(), 100);
    },
    destroy() {
      this.$store.commit('destroyCalendar');
    }
  },
  computed: {
    styles() {
      return `
        top: ${this.calendar.options.position.top}px; 
        left: ${this.calendar.options.position.left}px;
      `;
    },
    isTimeType() {
      return !!this.calendar.options.time
    },
    isSingleType() {
      return this.calendar.options.type === 'SINGLE';
    }
  },
  components: {
    ModalCalendarMain,
    ModalCalendarTime
  }
})
