import { dynamicsObject } from "@/interfaces"
import { Modal } from "@/interfaces/modal/modal.dto"

export const channel = (data: dynamicsObject): Array<Modal.Module> => {
  return [
    {
      id: 'channel-promocode',
      data: {
        channel: data?._id,
        tariff: '',
        discount: '',
        code: '',
        reusable: true,
      },
      inputs: [
        { required: true, name: 'Тариф *', placeholder: 'Выбери тариф', id: 'tariff', grid: '1 / 13', type: 'text', drop: true, showDrop: false, drop_data: [], show: true },
        { required: true, name: 'Процент скидки *', placeholder: 'Введи процент скидки', id: 'discount', grid: '1 / 13', mask: '###', type: 'text', drop: false, showDrop: false, drop_data: [], show: true },
        { required: false, name: 'Промокод', placeholder: 'Введи промокод (необязательно)', id: 'code', mask: 'XXXXXXXXXXXXXX', grid: '1 / 13', type: 'text', drop: false, showDrop: false, drop_data: [], show: true },
        { required: false, name: '', placeholder: 'Многоразовый', id: 'reusable', grid: '1 / 13', type: 'switcher', drop: false, showDrop: false, drop_data: [], show: true },
        { required: false, name: 'Перейти к таблице всех промокодов', placeholder: '/promocode', id: 'link', grid: '1 / 13', type: 'link', drop: false, showDrop: false, drop_data: [], show: true },
      ]
    },
    {
      id: 'channel-referral',
      data: {
        channel: data?._id,
        tariff: []
      },
      inputs: [
        { required: true, name: '', placeholder: '', id: 'tariff', grid: '1 / 13', type: 'tariff-list', drop: false, showDrop: false, drop_data: [], show: true },
      ]
    },
    {
      id: 'channel-children',
      data: {
        _id: data?._id,
        children: {
          id: data?.children?.id || '',
          name: data?.children?.name || '',
          active: data?.children?.active || false
        },
        static: 'Добавь бота в свой чат, после выдай ему права администратора'
      },
      inputs: [
        { required: true, name: '', placeholder: '', id: 'children', grid: '1 / 13', type: 'channel-children', drop: false, showDrop: false, drop_data: [], show: true },
        { required: true, name: '', placeholder: '', id: 'static', grid: '1 / 13', type: 'static', drop: false, showDrop: false, drop_data: [], show: true },
      ]
    },
    {
      id: 'channel-payment',
      data: {
        channel: data?._id,
        payment: data?.payment,
      },
      inputs: [
        { required: true, name: '', placeholder: '', id: 'payment', grid: '1 / 13', type: 'payment-list', drop: false, showDrop: false, drop_data: [], show: true },
      ]
    },
    {
      id: 'channel-quiz',
      data: {
        _id: data?._id,
        request: data?.request,
      },
      inputs: [
        { required: true, name: '', placeholder: '', id: 'quiz', grid: '1 / 13', type: 'quiz', drop: false, showDrop: false, drop_data: [], show: true },
      ]
    },
    {
      id: 'channel-tariff',
      data: {
        _id: '',
        name: '',
        channel: data?._id,
        duration: '',
        price: '',
        referral__days: '',
        referral__link: '',
        referral__status: false,
        referral__reusable: false,
        double_price__status: false,
        double_price__price: '',
        double_price__name: '',
        visibility: true,
        disposable: false,
        switcher: false,
      },
      inputs: [
        { required: false, name: 'Выбор тарифа', placeholder: 'Выбери тариф или создай новый', id: 'tariff', grid: '1 / 13', type: 'drop', drop: true, showDrop: false, drop_data: [], show: true },
        { required: true, name: 'Название тарифа *', placeholder: 'Введи название тарифа', id: 'name', grid: '1 / 13', type: 'text', drop: false, showDrop: false, drop_data: [], show: true },
        { required: true, name: 'Период оплаты *', placeholder: 'Введи кол-во дней', id: 'duration', grid: '1 / 7', mask: '#####', type: 'text', drop: false, showDrop: false, drop_data: [], show: true },
        { required: true, name: 'Стоимость, ₽ *', placeholder: 'Введи стоимость', id: 'price', grid: '7 / 13', mask: '#######', type: 'text', drop: false, showDrop: false, drop_data: [], show: true },
        { required: false, show: true, name: '', placeholder: 'Одноразовый', id: 'disposable', grid: '1 / 7', type: 'checkbox', drop: false, showDrop: false, drop_data: []},
        { required: false, show: true, name: '', placeholder: 'Отображать в боте', id: 'visibility', grid: '7 / 13', type: 'checkbox', drop: false, showDrop: false, drop_data: []},
        { required: false, name: '', placeholder: 'Реферальная программа', id: 'referral__status', grid: '1 / 13', type: 'switcher', drop: false, showDrop: false, drop_data: [], show: true },
        { referral__status: true, required: data?.referral?.status, show: data?.referral?.status, name: 'Дней рефералки *', placeholder: 'Введи кол-во дней', id: 'referral__days', grid: '1 / 13', type: 'text', mask: '####', drop: false, showDrop: false, drop_data: []},
        { referral__status: true, required: false, show: data?.referral?.status, name: 'Текст над реферальной ссылкой', placeholder: 'Введи текст', id: 'referral__link', grid: '1 / 13', type: 'text', drop: false, showDrop: false, drop_data: []},
        { referral__status: true, required: false, show: data?.referral?.status, name: '', placeholder: 'Многоразовая ссылка', id: 'referral__reusable', grid: '1 / 13', type: 'switcher', drop: false, showDrop: false, drop_data: []},
        { required: false, name: '', placeholder: 'Цена для новых подписчиков', id: 'double_price__status', grid: '1 / 13', type: 'switcher', drop: false, showDrop: false, drop_data: [], show: true },
        { double_price__status: true, required: data?.double_price?.status, name: 'Стоимость, ₽ *', show: data?.double_price?.status, placeholder: 'Введи стоимость', id: 'double_price__price', grid: '1 / 13', type: 'text', mask: '#######', drop: false, showDrop: false, drop_data: [] },
        { double_price__status: true, required: false, name: 'Название тарифа', show: data?.double_price?.status, placeholder: 'Введи название', id: 'double_price__name', grid: '1 / 13', type: 'text', drop: false, showDrop: false, drop_data: [] },
      ]
    },
    {
      id: 'channel-delete',
      data: {
        _id: data?._id,
        name: data?.name,
      },
      inputs: []
    },
  ]
}