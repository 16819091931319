import { dynamicsObject } from '@/interfaces';
import { Modal } from '@/interfaces/modal/modal.dto';
import { STATUS_DROP } from '@/store/common/default/modal';
import { useAPI } from '@/use';

export default async (modal: Modal.Main) => {
  const isPromocodeRequest = ['promocode-create', 'channel-promocode'].includes(modal.content.id);
  const isAutopostRequest = ['autopost-create', 'autopost-edit'].includes(modal.content.id);
  const isAdminRequest = ['admin-channel-list'].includes(modal.content.id);
  const isTariffRequest = ['tariff-create', 'tariff-edit'].includes(modal.content.id);
  const isChannelModals = ['channel-promocode', 'channel-tariff', 'channel-referral'].includes(modal.content.id);
  const isExtenstionTariff = modal.content.id === 'subscriber-extension';
  const isMailingRequest = ['mailing-create', 'mailing-edit'].includes(modal.content.id);
  
  if (isAdminRequest)
    getUserChannelList(modal)
  if (isAutopostRequest || isTariffRequest || isPromocodeRequest || isMailingRequest) 
    getChannelList(modal);
  if (isChannelModals || isExtenstionTariff)
    getTariffList(modal);
};

async function getUserChannelList(modal: Modal.Main) {
  const result = await useAPI().channel.getUserAllMethod(modal.inputs.data._id);
  modal.inputs.data.channels = result.data;
}

async function getChannelList(modal: Modal.Main) {
  const result = await useAPI().channel.getAllMethod();
  const input = modal.inputs.inputs.find(i => i.id === 'channel');
  if (input) input.drop_data = result.data;
}

async function getTariffList(modal: Modal.Main) {
  const result = await useAPI().tariff.getListMethod({ channel: [modal.inputs.data.channel] });
  const input = modal.inputs.inputs.find(i => i.id === 'tariff');
  if (!input) return;
  
  input.drop_data = result.data;
  if (modal.content.id === 'channel-tariff')
    input.drop_data = [{ _id: '', name: 'Создать новый' }, ...input.drop_data];
}

// function getStaffDatas(modal: Modal.Main) {
//   const employments = modal.inputs.inputs.find(i => i.id === 'employment');
//   const roles = modal.inputs.inputs.find(i => i.id === 'role');
//   const genders = modal.inputs.inputs.find(i => i.id === 'gender');

//   if (employments) {
//     employments.drop_data = EMPLOYMENT_TYPE_DROP;
//     next(modal, 'employment', EMPLOYMENT_TYPE_DROP);
//   }
//   if (roles) {
//     roles.drop_data = ROLES_TYPE_DROP;
//     next(modal, 'role', ROLES_TYPE_DROP);
//   }
//   if (genders) {
//     genders.drop_data = GENDER_LIST;
//     next(modal, 'gender', GENDER_LIST);
//   }
// }

function next(modal: Modal.Main, variable: string, array: Array<dynamicsObject>) {
  const element = array.find(e => e._id === modal.inputs.data[variable]);
  if (!element) return;
  modal.inputs.data[variable] = element.name;
  modal.inputs.data[variable + '_id'] = element._id;
}