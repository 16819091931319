
import { createVNode, defineComponent } from 'vue-demi';
import { mapGetters } from 'vuex';
import { ElNotification } from 'element-plus';
import GeneralContainer from './GeneralContainer.vue';

export default defineComponent({
  name: "GeneralNotification",
  computed: mapGetters(['generalNotification', 'user']),
  watch: {
    "$store.getters.generalNotification": function () {
      const data = this.generalNotification;
      if (data) {
        ElNotification({
          customClass: 'generalNotification',
          message: createVNode(GeneralContainer, data),
          duration: data?.timeout || 5000,
          onClick: this.notificationClick
        })
      }
    },
  },
  methods: {
    notificationClick() {
      if (this.generalNotification.type === 'payment')
        this.$store.commit('createModal', { id: 'user-replenishment', data: this.user });
      ElNotification.closeAll();
    }
  }
});
