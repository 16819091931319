
import { defineComponent } from "vue-demi";
import spaceFilter from '@/filters/space.filter';

export default defineComponent({
  emits: ['modal'],
  name: 'ProfileBalance',
  props: {
    balance: {
      type: Number,
      required: true
    },
    notification: {
      type: Number,
      required: true
    }
  },
  methods: {
    spaceFilter
  }
})
