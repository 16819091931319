
import { defineComponent } from "vue-demi";

export default defineComponent({
  name: 'IconButton',
  props: {
    table: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'default'
    }
  },
  data() {
    return {
      show: true,
    }
  },
  computed: {
    position() {
      return 1;
      // если вышел за экран - получить разницу размеров экрана и вышедшей плашки
    }
  }
});
