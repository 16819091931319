import { dynamicsObject } from "@/interfaces"
import { Modal } from "@/interfaces/modal/modal.dto"

export const mailing = (data: dynamicsObject): Array<Modal.Module> => {
  return [
    {
      id: 'mailing-create',
      data: {
        publishAt: '',
        name: '',
        content: '',
        channel: '',
        attachment: [],
        active: true,
        subscribers: [],
        statuses: [],
        list: [],
      },
      inputs: [
        { required: true, name: 'Название рассылки *', placeholder: 'Введи название рассылки', id: 'name', grid: '1 / 13', type: 'text', drop: false, showDrop: false, drop_data: [], show: true },
        { required: true, name: 'Когда опубликовать *', placeholder: 'Выбери дату публикации', id: 'publishAt', grid: '1 / 13', type: 'datetime', drop: false, showDrop: false, drop_data: [], show: true },
        { required: true, name: 'Текст рассылки *', placeholder: 'Введи текст', id: 'content', grid: '1 / 13', type: 'textarea', drop: false, showDrop: false, drop_data: [], show: true },
        { required: true, name: 'Сообщество *', placeholder: 'Выберите сообщество', id: 'channel', grid: '1 / 13', type: 'drop', drop: true, showDrop: false, drop_data: [], show: true },
        { required: false, name: 'Кому отправить (если не выбрать фильтр - отправится всем подписчикам сообщества)', placeholder: '', id: 'recipient', grid: '1 / 13', type: 'recipient', drop: false, showDrop: false, drop_data: [], show: true },
        { required: false, name: 'Вложения', placeholder: '', id: 'attachment', grid: '1 / 13', type: 'attachment', drop: false, showDrop: false, drop_data: [], show: true },
        { required: false, name: '', placeholder: 'Активная рассылка', id: 'active', grid: '1 / 13', type: 'switcher', drop: false, showDrop: false, drop_data: [], show: true },
      ]
    },
    {
      id: 'mailing-edit',
      data: {
        _id: data?._id,
        publishAt: data?.publishAt,
        name: data?.name,
        channel: data?.channel?.name,
        channel_id: data?.channel?._id,
        content: data?.content,
        attachment: data?.attachment || [],
        subscribers: data?.subscribers || [],
        statuses: data?.statuses || [],
        active: data?.active,
        list: [],
      },
      inputs: [
        { required: true, name: 'Название рассылки *', placeholder: 'Введи название рассылки', id: 'name', grid: '1 / 13', type: 'text', drop: false, showDrop: false, drop_data: [], show: true },
        { required: true, name: 'Когда опубликовать *', placeholder: 'Выбери дату публикации', id: 'publishAt', grid: '1 / 13', type: 'datetime', drop: false, showDrop: false, drop_data: [], show: true },
        { required: true, name: 'Текст рассылки *', placeholder: 'Введи текст', id: 'content', grid: '1 / 13', type: 'textarea', drop: false, showDrop: false, drop_data: [], show: true },
        { required: true, name: 'Сообщество *', placeholder: 'Выберите сообщество', id: 'channel', grid: '1 / 13', type: 'drop', drop: true, showDrop: false, drop_data: [], show: true },
        { required: false, name: 'Кому отправить (если не выбрать фильтр - отправится всем подписчикам сообщества)', placeholder: '', id: 'recipient', grid: '1 / 13', type: 'recipient', drop: false, showDrop: false, drop_data: [], show: true },
        { required: false, name: 'Вложения', placeholder: '', id: 'attachment', grid: '1 / 13', type: 'attachment', drop: false, showDrop: false, drop_data: [], show: true },
        { required: false, name: '', placeholder: 'Активная рассылка', id: 'active', grid: '1 / 13', type: 'switcher', drop: false, showDrop: false, drop_data: [], show: true },
      ]
    },
    {
      id: 'mailing-delete',
      data: {
        _id: data?._id,
        name: data?.name,
      },
      inputs: []
    },
    {
      id: 'support-create',
      data: {
        publishAt: '',
        name: '',
        content: '',
      },
      inputs: [
        { required: true, name: 'Название рассылки *', placeholder: 'Введи название рассылки', id: 'name', grid: '1 / 13', type: 'text', drop: false, showDrop: false, drop_data: [], show: true },
        { required: true, name: 'Когда опубликовать *', placeholder: 'Выбери дату публикации', id: 'publishAt', grid: '1 / 13', type: 'datetime', drop: false, showDrop: false, drop_data: [], show: true },
        { required: true, name: 'Текст рассылки *', placeholder: 'Введи текст', id: 'content', grid: '1 / 13', type: 'textarea', drop: false, showDrop: false, drop_data: [], show: true },
      ]
    },
    {
      id: 'support-delete',
      data: {
        _id: data?._id,
        name: data?.name,
      },
      inputs: []
    },
  ]
}