import { Modal } from "@/interfaces/modal/modal.dto";

export const tariff: Array<Modal.Content> = [
  {
    id: 'tariff-create',
    title: 'Создание тарифа',
    action: 'add',
    request: '/api/tariff',
    method: 'post',
    cancelName: 'Отмена',
    submitName: 'Создать',
    emit: 'table-action',
  },
  {
    id: 'tariff-edit',
    title: 'Редактирование тарифа',
    action: 'edit',
    request: '/api/tariff',
    method: 'patch',
    cancelName: 'Отмена',
    submitName: 'Сохранить',
    emit: 'table-action',
  },
  {
    id: 'tariff-delete',
    title: '',
    action: 'delete',
    request: '/api/tariff',
    method: 'delete',
    message: 'удалить тариф',
    additionalMessage: 'Пользователи, которые оформили подписку на этот тариф, не смогут увидеть/продлить подписку на этот тариф!',
    cancelName: 'Отмена',
    submitName: 'Удалить',
    emit: 'table-action',
  },
  {
    id: 'tariff-payment-update',
    title: 'Привет, важная новость!',
    action: 'static',
    additionalMessage: `Продамус изменил условия оплаты вне РФ.
    
    Что изменилось? 
    Теперь при оплате подписки вне РФ - её стоимость должна быть не менее 740₽. Только в этом случае пользователь сможет произвести оплату.
    
    Что делать?
    Пожалуйста, проверь тарифы. Поменяй стоимость при необходимости!
    
    Если возникнут вопросы, пиши в поддержку`,
    submitName: 'Закрыть',
  },
];