import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "common--calendar" }
const _hoisted_2 = { class: "common--calendar--month flex-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_down_arrow_icon = _resolveComponent("down-arrow-icon")!
  const _component_container_dates = _resolveComponent("container-dates")!
  const _component_container_months = _resolveComponent("container-months")!
  const _component_container_years = _resolveComponent("container-years")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "prev flex-center",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectType(-1)))
      }, [
        _createVNode(_component_down_arrow_icon)
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(["type-name", { cursor: _ctx.isSingleType }]),
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleType && _ctx.toggleType(...args)))
      }, _toDisplayString(_ctx.typeFilter), 3),
      _createElementVNode("div", {
        class: "next flex-center",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.selectType(1)))
      }, [
        _createVNode(_component_down_arrow_icon)
      ])
    ]),
    (_ctx.type === 'dates')
      ? (_openBlock(), _createBlock(_component_container_dates, {
          key: 0,
          dates: _ctx.dates,
          isSingleType: _ctx.isSingleType,
          currentMonth: _ctx.currentMonth,
          onSelectedDate: _cache[3] || (_cache[3] = (dates) => _ctx.$emit('selectedDate', dates))
        }, null, 8, ["dates", "isSingleType", "currentMonth"]))
      : (_ctx.type === 'months')
        ? (_openBlock(), _createBlock(_component_container_months, {
            key: 1,
            dates: _ctx.dates,
            isSingleType: _ctx.isSingleType,
            currentMonth: _ctx.currentMonth,
            onSelectMonth: _ctx.selectMonth
          }, null, 8, ["dates", "isSingleType", "currentMonth", "onSelectMonth"]))
        : (_ctx.type === 'years')
          ? (_openBlock(), _createBlock(_component_container_years, {
              key: 2,
              dates: _ctx.dates,
              isSingleType: _ctx.isSingleType,
              currentMonth: _ctx.currentMonth,
              onSelectYear: _ctx.selectYear
            }, null, 8, ["dates", "isSingleType", "currentMonth", "onSelectYear"]))
          : _createCommentVNode("", true)
  ]))
}