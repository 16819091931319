import { dynamicsObject } from "@/interfaces"
import { Modal } from "@/interfaces/modal/modal.dto"

export const subscriber = (data: dynamicsObject): Array<Modal.Module> => {
  return [
    {
      id: 'subscriber-extension',
      data: {
        _id: data?._id,
        channel: data?.channel?._id,
        tariff: data?.tariff?.name,
        tariff_id: data?.tariff?._id
      },
      inputs: [
        { required: true, name: 'Тариф *', placeholder: 'Выбери тариф', id: 'tariff', grid: '1 / 13', type: 'text', drop: true, showDrop: false, drop_data: [], show: true },
      ]
    },
    {
      id: 'subscriber-ban',
      data: {
        _id: data?._id,
        reason: ''
      },
      inputs: [
        { required: false, name: 'Причина', placeholder: 'Введи причину блокировки (необязательно)', id: 'reason', grid: '1 / 13', type: 'textarea', drop: false, showDrop: false, drop_data: [], show: true },
      ]
    },
    {
      id: 'subscriber-unban',
      data: {
        _id: data?._id,
      },
      inputs: []
    },
    {
      id: 'subscriber-referral-list',
      data: {
        _id: data?._id,
        referral: data?.referral,
      },
      inputs: [
        { required: true, disabled: true, name: 'Приглашенные', placeholder: '', id: 'referral', grid: '1 / 13', type: 'static-list', drop: false, showDrop: false, drop_data: [], show: true },
      ]
    },
    {
      id: 'subscriber-quiz',
      data: {
        _id: data?._id,
        questions: data?.questions,
        email: data?.email,
        phone: data?.phone
      },
      inputs: [
        { required: true, disabled: true, name: 'Телефон', placeholder: '', id: 'phone', grid: '1 / 13', type: 'text', drop: false, showDrop: false, drop_data: [], show: data?.phone },
        { required: true, disabled: true, name: 'Email', placeholder: '', id: 'email', grid: '1 / 13', type: 'text', drop: false, showDrop: false, drop_data: [], show: data?.email },
        { required: true, disabled: true, name: '', placeholder: '', id: 'questions', grid: '1 / 13', type: 'quiz-answer', drop: false, showDrop: false, drop_data: [], show: !!data?.questions?.length },
      ]
    },
  ]
}