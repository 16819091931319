import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7242f26b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "calendar--section-container" }
const _hoisted_2 = {
  key: 0,
  class: "calendar--action"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_modal_calendar_main = _resolveComponent("modal-calendar-main")!
  const _component_modal_calendar_time = _resolveComponent("modal-calendar-time")!

  return (_openBlock(), _createElementBlock("section", {
    class: "calendar--section common flex-column",
    ref: "calendar",
    style: _normalizeStyle(_ctx.styles)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_modal_calendar_main, {
        dates: _ctx.calendar.dates,
        isSingleType: _ctx.isSingleType,
        onSelectedDate: _ctx.selectedDate
      }, null, 8, ["dates", "isSingleType", "onSelectedDate"]),
      (_ctx.isSingleType && _ctx.isTimeType)
        ? (_openBlock(), _createBlock(_component_modal_calendar_time, {
            key: 0,
            dates: _ctx.calendar.dates
          }, null, 8, ["dates"]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.isTimeType)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("button", {
            class: "btn btn--white",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.destroy && _ctx.destroy(...args)))
          }, "Отменить"),
          _createElementVNode("button", {
            class: "btn btn--small",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.actionTerms && _ctx.actionTerms(...args)))
          }, "Выбрать")
        ]))
      : _createCommentVNode("", true)
  ], 4))
}