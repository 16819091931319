
import { defineComponent } from "vue-demi";
import { BotpayIcon } from '@/plugins/icons';

export default defineComponent({
  name: 'GeneralNotificationContainer',
  props: {
    type: {
      type: String,
      required: true
    },
    message: {
      type: String,
      required: true
    }
  },
  components: {
    BotpayIcon
  }
})
