import { Modal } from "@/interfaces/modal/modal.dto";

export const mailing: Array<Modal.Content> = [
  {
    id: 'mailing-create',
    title: 'Добавить рассылку',
    action: 'add',
    request: '/api/mailing',
    fileRequest: '/api/file/mailing/attachment',
    method: 'post',
    fileMethod: 'patch',
    cancelName: 'Отмена',
    submitName: 'Создать',
    emit: 'table-action',
  },
  {
    id: 'mailing-edit',
    title: 'Редактировать рассылку',
    action: 'edit',
    request: '/api/mailing',
    fileRequest: '/api/file/mailing/attachment',
    method: 'patch',
    fileMethod: 'patch',
    cancelName: 'Отмена',
    submitName: 'Сохранить',
    emit: 'table-action',
  },
  {
    id: 'mailing-delete',
    title: '',
    action: 'delete',
    request: '/api/mailing',
    method: 'delete',
    message: 'удалить рассылку',
    cancelName: 'Отмена',
    submitName: 'Удалить',
    emit: 'table-action',
  },
  //
  {
    id: 'support-create',
    title: 'Добавить рассылку',
    action: 'add',
    request: '/api/support/mailing',
    method: 'post',
    cancelName: 'Отмена',
    submitName: 'Создать',
    emit: 'support-action',
  },
  {
    id: 'support-delete',
    title: '',
    action: 'delete',
    request: '/api/mailing',
    method: 'delete',
    message: 'удалить рассылку',
    cancelName: 'Отмена',
    submitName: 'Удалить',
    emit: 'support-action',
  },
];