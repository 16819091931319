
import { defineComponent, markRaw, PropType } from "vue-demi";
import { User } from "@/interfaces/user/user.dto";
import { currentImageSize } from '@/utils/files';
import { SubscriberIcon, LeftArrowIcon, LogoutIcon, UserEditIcon, PolicyIcon, SupportIcon, OfferIcon, PaymentInfoIcon, InfoIcon, AutopostIcon, MailingIcon } from '@/plugins/icons';
import IconButton from '@/components/common/button/IconButton.vue';

export default defineComponent({
  emits: ['cancelDrop'],
  name: 'ProfileMobileDrop',
  props: {
    user: {
      type: Object as PropType<User.Dto>,
      required: true
    }
  },
  data() {
    return {
      blocks: [
        { id: 'referral', name: 'Личные данные', icon: markRaw(UserEditIcon), route: '/profile' },
        { id: 'tariff', name: 'Автопостинг', icon: markRaw(AutopostIcon), route: '/autopost' },
        { id: 'autopost', name: 'Рассылки', icon: markRaw(MailingIcon), route: '/mailing' },
        { id: 'promocode', name: 'Покинуть аккаунт', icon: markRaw(LogoutIcon), route: 'logout' },
      ],
      info: [
        { id: 'policy', name: 'Политика конфиденциальности', icon: markRaw(PolicyIcon) },
        { id: 'info', name: 'Юридическая информация', icon: markRaw(InfoIcon) },
        { id: 'payment', name: 'Правила оплаты и возврата', icon: markRaw(PaymentInfoIcon) },
        { id: 'offer', name: 'Договор оферты', icon: markRaw(OfferIcon) },
        { id: 'support', name: 'Поддержка', icon: markRaw(SupportIcon) },
      ]
    }
  },
  methods: {
    currentImageSize,
    router(route: string) {
      if (route === 'logout') return this.$store.dispatch('logoutAction');
      this.$emit('cancelDrop');
      this.$router.push(route);
    }
  },
  components: {
    SubscriberIcon,
    LeftArrowIcon,
    IconButton
  }
})
