import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0e04955e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "message fz12 lh16"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex-center cursor", [{ table: _ctx.table }, _ctx.type]]),
    onMouseenter: _cache[0] || (_cache[0] = ($event: any) => (_ctx.show = true)),
    onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.show = false))
  }, [
    _renderSlot(_ctx.$slots, "default"),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.message && _ctx.show)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.message), 1))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 34))
}