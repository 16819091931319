import { dynamicsObject } from "@/interfaces"
import { Modal } from "@/interfaces/modal/modal.dto"

export const promocode = (data: dynamicsObject): Array<Modal.Module> => {
  return [
    {
      id: 'promocode-create',
      data: {
        channel: '',
        tariff: '',
        discount: '',
        code: '',
        reusable: true,
      },
      inputs: [
        { required: true, name: 'Сообщество *', placeholder: 'Выбери сообщество', id: 'channel', grid: '1 / 13', type: 'text', drop: true, showDrop: false, drop_data: [], show: true },
        { required: true, name: 'Тариф *', placeholder: 'Выбери тариф', id: 'tariff', grid: '1 / 13', type: 'text', drop: true, showDrop: false, drop_data: [], show: true },
        { required: true, name: 'Процент скидки *', placeholder: 'Введи процент скидки', id: 'discount', grid: '1 / 13', mask: '###', type: 'text', drop: false, showDrop: false, drop_data: [], show: true },
        { required: false, name: 'Промокода', placeholder: 'Введи ваш промокод (необязательно)', id: 'code', mask: 'XXXXXXXX', grid: '1 / 13', type: 'text', drop: false, showDrop: false, drop_data: [], show: true },
        { required: false, name: '', placeholder: 'Многоразовый', id: 'reusable', grid: '1 / 13', type: 'switcher', drop: false, showDrop: false, drop_data: [], show: true },
      ]
    },
    {
      id: 'promocode-view',
      data: {
        _id: data?._id,
        code: data?.code,
      },
      inputs: [
        { required: false, name: 'Сгенерированный промокод', placeholder: '', id: 'code', grid: '1 / 13', type: 'copy', drop: false, showDrop: false, drop_data: [], show: true },
      ]
    },
    {
      id: 'promocode-delete',
      data: {
        _id: data?._id,
      },
      inputs: []
    },
  ]
}