import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createCommentVNode as _createCommentVNode, Transition as _Transition, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1d448848"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sidebar" }
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  key: 0,
  class: "more"
}
const _hoisted_5 = { class: "flex-column" }
const _hoisted_6 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("aside", _hoisted_1, [
    _createElementVNode("nav", null, [
      _createElementVNode("ul", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentMobileSidebar, (element) => {
          return (_openBlock(), _createBlock(_component_router_link, {
            to: element.route,
            key: element.route,
            custom: ""
          }, {
            default: _withCtx(() => [
              _createElementVNode("li", {
                class: _normalizeClass(["center flex-column items-center cursor fz10 lh12", { selected: _ctx.isSectionRoute(element) }]),
                onClick: ($event: any) => (_ctx.navigate(element))
              }, [
                (_openBlock(), _createBlock(_resolveDynamicComponent(element.icon), {
                  color: _ctx.isSectionRoute(element) ? '#01BCB5' : ''
                }, null, 8, ["color"])),
                _createElementVNode("span", null, _toDisplayString(element.name), 1)
              ], 10, _hoisted_3)
            ]),
            _: 2
          }, 1032, ["to"]))
        }), 128))
      ]),
      _createVNode(_Transition, { name: "icon" }, {
        default: _withCtx(() => [
          (_ctx.showMore)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createElementVNode("ul", _hoisted_5, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentMoreSidebar, (element) => {
                    return (_openBlock(), _createBlock(_component_router_link, {
                      to: element.route,
                      key: element.route,
                      custom: ""
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("li", {
                          class: _normalizeClass(["center flex items-center cursor lh16", { selected: _ctx.isSectionRoute(element) }]),
                          onClick: ($event: any) => (_ctx.navigate(element))
                        }, [
                          (_openBlock(), _createBlock(_resolveDynamicComponent(element.icon), {
                            color: _ctx.isSectionRoute(element) ? '#01BCB5' : ''
                          }, null, 8, ["color"])),
                          _createElementVNode("span", null, _toDisplayString(element.name), 1)
                        ], 10, _hoisted_6)
                      ]),
                      _: 2
                    }, 1032, ["to"]))
                  }), 128))
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ])
  ]))
}