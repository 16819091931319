import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-79d4dc88"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modalover" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_modal_header = _resolveComponent("modal-header")!
  const _component_modal_container = _resolveComponent("modal-container")!
  const _component_modal_actions = _resolveComponent("modal-actions")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", {
      class: "overflow",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.removeModal && _ctx.removeModal(...args)))
    }),
    _createElementVNode("div", {
      class: _normalizeClass(["modal", { 'overflow-y': _ctx.isOverflow }]),
      onDragover: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"]))
    }, [
      _createVNode(_component_modal_header, {
        data: _ctx.data,
        onRemoveModal: _ctx.removeModal
      }, null, 8, ["data", "onRemoveModal"]),
      _createVNode(_component_modal_container, { data: _ctx.data }, null, 8, ["data"]),
      (_ctx.data.content.action !== 'static')
        ? (_openBlock(), _createBlock(_component_modal_actions, {
            key: 0,
            data: _ctx.data,
            pending: _ctx.pendingRequest,
            onRemoveModal: _ctx.removeModal,
            onSubmit: _ctx.submit
          }, null, 8, ["data", "pending", "onRemoveModal", "onSubmit"]))
        : _createCommentVNode("", true)
    ], 34)
  ]))
}