import { dynamicsObject } from "@/interfaces";
import { promocode } from "./promocode";
import { tariff } from "./tariff";
import { autopost } from "./autopost";
import { mailing } from "./mailing";
import { channel } from "./channel";
import { subscriber } from "./subscriber";
import { order } from "./order";
import { user } from "./user";
import { owner } from "./owner";
import { advertising } from "./advertising";

export default ({ id, data }: { id: string, data: dynamicsObject }) => {
  const contents = [
    ...user,
    ...owner,
    ...order,
    ...tariff,
    ...channel,
    ...mailing,
    ...autopost,
    ...promocode,
    ...subscriber,
    ...advertising,
  ];
  return contents.find(content => content.id === id) || contents[0];
}