import { Modal } from "@/interfaces/modal/modal.dto";

export const order: Array<Modal.Content> = [
  {
    id: 'order-edit',
    title: '',
    action: 'delete',
    request: '/api/order/status',
    method: 'patch',
    cancelName: 'Отмена',
    submitName: 'Выплачено',
    message: 'подтвердить выплату заказа',
    additionalMessage: 'После подтвержения выплаты, пользователю будет отправлено уведомление об успешном выводе средств!',
    emit: 'table-action',
  },
];