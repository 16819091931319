import { dynamicsObject, socketResponse } from "@/interfaces";
// import { isCreater, ObjectClearRef } from "@/store/common";

export function mainSocketFollow(self: any) {
  socketJoinRoom(self, [ 
    self.user._id, 
    'finance-action',
    'marketing-action',
  ]);
  socketFollowActionUser(self);
  socketFollowActionTelegram(self);
}

export function socketJoinRoom(self: any, rooms: string | string[]) {
  console.log('connect');
  self.socket.emit('room-join', { user: self.socket.id, room: rooms }, (data: dynamicsObject) => {
    console.log(data);
  });
};

export function socketLeaveRoom(self: any, rooms: string | string[]) {
  self.socket.emit('room-leave', { user: self.socket.id, room: rooms }, (data: dynamicsObject) => {
    console.log(data);
  });
};

function socketFollowActionUser(self: any) {
  self.socket.on('user-action', ({ status, data }: socketResponse) => {
    if (!status) return;
    if (data.action === 'updated')
      return self.$store.commit('createNotification', {
        status: 'info',
        message: 'Данные вашего аккаунта были обновлены! Пожалуйста, обновите страницу!'
      });
    if (data.action === 'deleted') {
      self.$store.commit('createGeneralNotification', {
        type: 'static',
        message: 'Ваш аккаунт был удален!'
      });
      return self.$store.dispatch('logoutAction');
    }
    if (data.action === 'avatar-updated') {
      self.$store.commit('createGeneralNotification', {
        type: 'static',
        message: 'Аватар успешно изменен!'
      });
      return self.$store.commit('updateUserAvatar', data);
    }
    if (data.action === 'card-billing')
      return self.emitter.emit('botpay-card-billing', data.doc);
    if (data.action === 'balance-updated') {
      return self.$store.commit('updateUserBalance', { type: 'common', value: data.doc });
    }
  });
}

function socketFollowActionTelegram(self: any) {
  self.socket.on('telegram-action', ({ status, data }: socketResponse) => {
    if (!status) return;
    if (data.action === 'support-action')
      return self.emitter.emit('telegram-support-action', data.data);
    if (data.action === 'channel-create')
      return self.emitter.emit('telegram-channel-create', data.data);
    if (data.action === 'channel-children')
      return self.emitter.emit('telegram-channel-children', data.data);
    
  });
}