import zeroFilter from "@/filters/zero.filter";
import { DAYS_NAMES, MONTHS_NAMES_CASE } from "@/store/common/default/dates";

export const getCorrectProfileDay = () => {
  const date = new Date();
  const day = date.getDate();
  const month = date.getMonth();
  const weekDay = date.getDay();
  return `${DAYS_NAMES[weekDay]}, ${day} ${MONTHS_NAMES_CASE[month]}`;
}

export const getCorrectProfileTimesDay = () => {
  const date = new Date();
  const hours = date.getHours();
  const data = [
    { min: 6, max: 11, value: 'Доброе утро' },
    { min: 12, max: 17, value: 'Добрый день' },
    { min: 18, max: 23, value: 'Добрый вечер' },
    { min: 0, max: 5, value: 'Доброй ночи' },
  ];
  return data.find(element => element.min <= hours && element.max >= hours)?.value || 'Добрый день';
}

export const typeTermTask = (status: boolean, endedAt: Date) => {
  const date = getDateNullTime(new Date());
  const difference = differenceTime(endedAt, date as Date);
  
  if (difference >= 2 || status || !endedAt) return `background-color: #DBEABB`;
  if (difference >= 1 && difference < 2 || (Number(new Date(endedAt)) > Date.now())) return `background-color: rgba(255, 234, 126, 0.5)`;
  return 'background-color: rgba(255, 114, 94, 0.5)';
  
}

export function getDateNullTime(date: Date, type = 'date', option?: 'betweenTime') {
  const d = new Date(date);
  const day = type === 'date' ? zeroFilter(d.getDate()) : '01';
  const month = type === 'year' ? '01' : zeroFilter(d.getMonth() + 1);

  if (option === 'betweenTime') return `${d.getFullYear()}-${month}-${day}`;
  return new Date(`${d.getFullYear()}-${month}-${day}T00:00`);
}

export const differenceTime = (first: Date, second: Date) => (new Date(first).getTime() - second.getTime()) / (1000 * 3600 * 24);

export const monthList = () => {
  let months = [] as Array<{ month: number, dates: Array<{ date: Date, position: number, current: boolean }> }>;
  const firstMonth = getMonth(-1);
  for (let i = 0; i < 12; i++) {
    const month = new Date(firstMonth.setMonth(firstMonth.getMonth() + 1));
    months = [...months, { month: month.getMonth(), dates: monthDates(month) }];
  }
  return months;
}

export const monthDates = (date: Date) => {
  const firstDate = firstMonthDate(date);
  const lastDate = lastMonthDate(date);
  const currentDate = getDateNullTime(new Date());
  let dates = [] as Array<{ date: Date, position: number, current: boolean }>;
  for (let i = 0; i < lastDate.getDate(); i++) {
    const date = new Date(firstDate.setDate(firstDate.getDate() + (!i ? 0 : 1)));
    dates = [...dates, { date, position: 0, current: +date === +currentDate }];
  }
  return dates;
}

export const getMonth = (inc: number) => {
  const date = new Date();
  return new Date(date.setMonth(date.getMonth() + (4 * inc)));
}

export const lastMonthDate = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
}
export const firstMonthDate = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth());
}
 