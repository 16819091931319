
import { defineComponent, PropType } from "vue-demi";
import { DownArrowIcon } from '@/plugins/icons';
import { MONTHS_NAMES } from "@/store/common/default/dates";
import { CalendarDates } from "@/interfaces/common/calendar";
import { switchType } from '@/utils/date/calendar';
import dateFilter from '@/filters/date.filter';

import ContainerDates from './container/Dates.vue';
import ContainerMonths from './container/Months.vue';
import ContainerYears from './container/Years.vue';
 
export default defineComponent({
  emits: ['selectedDate'],
  name: 'ModalCalendarMain',
  props: {
    dates: {
      type: Object as PropType<CalendarDates>,
      required: true
    },
    isSingleType: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      type: 'dates' as 'dates' | 'months' | 'years',
      currentMonth: new Date(),
    }
  },
  created() {
    if (this.dates.startedAt) {
      this.dates.startedAt = new Date(this.dates.startedAt);
      this.currentMonth = new Date(this.dates.startedAt);
    }
    if (this.dates.endedAt) {
      this.dates.endedAt = new Date(this.dates.endedAt);
      this.currentMonth = new Date(this.dates.endedAt);
    }
  },
  computed: {
    typeFilter() {
      if (this.type === 'dates') return `${MONTHS_NAMES[this.currentMonth.getMonth()]}, ${this.currentMonth.getFullYear()}`;
      if (this.type === 'months') return `${this.currentMonth.getFullYear()}`;
      const first = Math.floor(this.currentMonth.getFullYear() / 10) * 10;
      return `${first} - ${first + 10}`;
    }
  },
  methods: {
    toggleType() {
      if (!this.isSingleType) return this.type === 'dates';
      if (this.type === 'dates') return this.type = 'months';
      if (this.type === 'months') return this.type = 'years';
      if (this.type === 'years') return this.type = 'dates';
    },
    selectType(inc: number) {
      this.currentMonth = switchType(this.currentMonth as Date, inc, this.type);
    },
    selectYear(date: Date) {
      this.dates.startedAt?.setFullYear(date.getFullYear());
      this.currentMonth.setFullYear(date.getFullYear())
      setTimeout(() => {
        this.type = 'months';
      }, 10);
    },
    selectMonth(date: Date) {
      this.dates.startedAt?.setMonth(date.getMonth());
      this.currentMonth.setMonth(date.getMonth());
      setTimeout(() => {
        this.type = 'dates';
      }, 10);
    },
    dateFilter
  },
  components: {
    DownArrowIcon,
    ContainerDates,
    ContainerMonths,
    ContainerYears
  }
})
