import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-553b308f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex-center-between" }
const _hoisted_2 = { class: "title flex items-center" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  key: 2,
  href: "/channel/create",
  class: "fz13"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_left_arrow_icon = _resolveComponent("left-arrow-icon")!
  const _component_icon_button = _resolveComponent("icon-button")!
  const _component_profile = _resolveComponent("profile")!
  const _component_profile_owner = _resolveComponent("profile-owner")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.isBackRoute)
        ? (_openBlock(), _createBlock(_component_icon_button, {
            key: 0,
            onClick: _ctx.backRoute
          }, {
            default: _withCtx(() => [
              _createVNode(_component_left_arrow_icon)
            ]),
            _: 1
          }, 8, ["onClick"]))
        : _createCommentVNode("", true),
      (_ctx.isBackName && _ctx.isDesktop)
        ? (_openBlock(), _createElementBlock("h1", _hoisted_3, _toDisplayString(_ctx.currentRouteName), 1))
        : _createCommentVNode("", true),
      (_ctx.isNewChannel)
        ? (_openBlock(), _createElementBlock("a", _hoisted_4, "Добавить новое"))
        : _createCommentVNode("", true)
    ]),
    (_ctx.isUser)
      ? (_openBlock(), _createBlock(_component_profile, {
          key: 0,
          user: _ctx.user,
          isDesktop: _ctx.isDesktop
        }, null, 8, ["user", "isDesktop"]))
      : (_openBlock(), _createBlock(_component_profile_owner, {
          key: 1,
          user: _ctx.user
        }, null, 8, ["user"]))
  ]))
}