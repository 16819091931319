import { Modal } from "@/interfaces/modal/modal.dto";

export const channel: Array<Modal.Content> = [
  {
    id: 'channel-promocode',
    title: 'Сгенерировать промокод',
    action: 'add',
    request: '/api/promocode',
    method: 'post',
    emit: 'channel-promocode',
    cancelName: 'Отмена',
    submitName: 'Сгенерировать',
  },
  {
    id: 'channel-tariff',
    title: 'Настройка тарифа',
    action: 'edit',
    request: '/api/tariff/channel',
    method: 'patch',
    cancelName: 'Отмена',
    submitName: 'Сохранить',
  },
  {
    id: 'channel-quiz',
    title: 'Quiz',
    action: 'edit',
    request: '/api/channel/request',
    method: 'patch',
    cancelName: 'Отмена',
    submitName: 'Сохранить',
  },
  {
    id: 'channel-referral',
    title: 'Реферальная программа',
    action: 'static',
  },
  {
    id: 'channel-children',
    title: 'Чат-болталка',
    action: 'static',
  },
  {
    id: 'channel-payment',
    title: 'Платежные данные',
    action: 'static',
  },
  {
    id: 'channel-delete',
    title: '',
    action: 'delete',
    request: '/api/channel',
    method: 'delete',
    message: 'удалить канал',
    additionalMessage: 'Удаление канала приведет к потере всех данных, связанных с каналом, а так же бот покинет канал в телеграме!',
    cancelName: 'Отмена',
    submitName: 'Удалить',
    emit: 'channel-delete',
  },
];