import { Modal } from "@/interfaces/modal/modal.dto";

export const autopost: Array<Modal.Content> = [
  {
    id: 'autopost-create',
    title: 'Добавить пост',
    action: 'add',
    request: '/api/autopost',
    fileRequest: '/api/file/autopost/attachment',
    method: 'post',
    fileMethod: 'patch',
    cancelName: 'Отмена',
    submitName: 'Создать',
    emit: 'table-action',
  },
  {
    id: 'autopost-edit',
    title: 'Редактировать пост',
    action: 'edit',
    request: '/api/autopost',
    fileRequest: '/api/file/autopost/attachment',
    method: 'patch',
    fileMethod: 'patch',
    cancelName: 'Отмена',
    submitName: 'Сохранить',
    emit: 'table-action',
  },
  {
    id: 'autopost-delete',
    title: '',
    action: 'delete',
    request: '/api/autopost',
    method: 'delete',
    message: 'удалить пост',
    cancelName: 'Отмена',
    submitName: 'Удалить',
    emit: 'table-action',
  },
];