import { Modal } from "@/interfaces/modal/modal.dto";

export const owner: Array<Modal.Content> = [
  {
    id: 'admin-channel-list',
    title: 'Список сообществ',
    action: 'static',
  },
  {
    id: 'admin-edit',
    title: 'Редактирование администратора',
    action: 'add',
    request: '/api/user/source',
    method: 'patch',
    cancelName: 'Отмена',
    submitName: 'Сохранить',
    emit: 'table-action',
  },
  {
    id: 'admin-delete',
    title: '',
    action: 'delete',
    request: '/api/user',
    emit: 'table-action',
    method: 'delete',
    message: 'удалить администратора',
    additionalMessage: 'Удаление приведет к безвозвратной потере всех сообществ этого администратора!',
    cancelName: 'Отмена',
    submitName: 'Удалить',
  },
];