import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5d87747d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "profile--drop common" }
const _hoisted_2 = { class: "section flex-center" }
const _hoisted_3 = { class: "section--info flex-center" }
const _hoisted_4 = { class: "name" }
const _hoisted_5 = { class: "fw500" }
const _hoisted_6 = { class: "fz12" }
const _hoisted_7 = { class: "avatar" }
const _hoisted_8 = {
  key: 0,
  alt: "Аватар"
}
const _hoisted_9 = {
  key: 1,
  class: "flex-center"
}
const _hoisted_10 = { class: "flex-column" }
const _hoisted_11 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_subscriber_icon = _resolveComponent("subscriber-icon")!
  const _component_cancel_icon = _resolveComponent("cancel-icon")!
  const _directive_lazy = _resolveDirective("lazy")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, "@" + _toDisplayString(_ctx.user.login), 1),
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.user.name), 1)
        ]),
        _createElementVNode("div", _hoisted_7, [
          (_ctx.user.avatar)
            ? _withDirectives((_openBlock(), _createElementBlock("img", _hoisted_8, null, 512)), [
                [_directive_lazy, _ctx.currentImageSize(_ctx.user.avatar.src, 50, 50)]
              ])
            : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createVNode(_component_subscriber_icon)
              ]))
        ])
      ]),
      _createVNode(_component_cancel_icon, {
        class: "cursor",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('cancelDrop')))
      })
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sections, (section, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "section",
        key: index
      }, [
        _createElementVNode("ul", _hoisted_10, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(section, (route) => {
            return (_openBlock(), _createElementBlock("li", {
              class: "cursor",
              key: route.route,
              onClick: ($event: any) => (_ctx.router(route.route))
            }, _toDisplayString(route.name), 9, _hoisted_11))
          }), 128))
        ])
      ]))
    }), 128))
  ]))
}