import { dynamicsObject } from "@/interfaces";
import { autopost } from "./autopost";
import { mailing } from "./mailing";
import { channel } from "./channel";
import { order } from "./order";
import { promocode } from "./promocode";
import { subscriber } from "./subscriber";
import { tariff } from "./tariff";
import { user } from "./user";
import { owner } from "./owner";
import { advertising } from "./advertising";

export default ({ id, data }: { id: string, data: dynamicsObject }) => {
  const inputs = [
    ...user(data),
    ...owner(data),
    ...order(data),
    ...tariff(data),
    ...channel(data),
    ...mailing(data),
    ...autopost(data),
    ...promocode(data),
    ...subscriber(data),
    ...advertising(data),
  ]
  return inputs.find(input => input.id === id) || inputs[0];
}