import { markRaw, Component } from 'vue-demi';
import { AdminIcon, AdvertisingIcon, ChannelIcon, MainIcon, MoreIcon, OrderIcon, PartnerIcon, PaymentIcon, PromocodeIcon, ReferralIcon, SoftpayIcon, SubscriberIcon, SupportSidebarIcon, TariffIcon, TransactionIcon } from "@/plugins/icons";

interface SIDEBAR {
  name: string
  icon: Component
  id: string
  separator: boolean
  route: string,
  mobile: boolean
}

export const SIDEBAR = [
  { mobile: true, name: 'Сообщества', icon: markRaw(ChannelIcon), id: "user-channel", separator: false, route: "/channel" },
  { mobile: true, name: 'Подписчики', icon: markRaw(SubscriberIcon), id: "user-subscriber", separator: false, route: "/subscriber" },
  { mobile: true, name: 'Тарифы', icon: markRaw(TariffIcon), id: "user-tariff", separator: false, route: "/tariff" },
  { mobile: false, name: 'Промокоды', icon: markRaw(PromocodeIcon), id: "user-promocode", separator: false, route: "/promocode" },
  { mobile: true, name: 'Транзакции', icon: markRaw(TransactionIcon), id: "user-transaction", separator: false, route: "/transaction" },
  { mobile: false, name: 'Платежные данные', icon: markRaw(PaymentIcon), id: "user-payment-details", separator: false, route: "/payment/details" },
  { mobile: false, name: 'Партнёрка', icon: markRaw(PartnerIcon), id: "user-partner", separator: true, route: "/partner" },
  { mobile: false, name: 'Рефералка', icon: markRaw(ReferralIcon), id: "user-referral", separator: false, route: "/referral" },
  // { mobile: false, name: 'Поддержка', icon: markRaw(ReferralIcon), id: "user-support", separator: true, route: "/support" },
  { mobile: true, name: 'Еще', icon: markRaw(MoreIcon), id: "user-more", separator: false, route: "/referral" },
] as SIDEBAR[];

export const SIDEBAR_OWNER = [
  { mobile: false, name: 'Softpay', icon: markRaw(SoftpayIcon), id: "softpay", separator: false, route: "/owner" },
  { mobile: true, name: 'Главная', icon: markRaw(MainIcon), id: "owner-main", separator: false, route: "/owner" },
  { mobile: true, name: 'Админы', icon: markRaw(AdminIcon), id: "owner-admin", separator: false, route: "/owner/admin" },
  { mobile: true, name: 'Сообщества', icon: markRaw(ChannelIcon), id: "owner-channel", separator: false, route: "/owner/channel" },
  { mobile: true, name: 'Подписчики', icon: markRaw(SubscriberIcon), id: "owner-subscriber", separator: false, route: "/owner/subscriber" },
  { mobile: false, name: 'Тарифы', icon: markRaw(TariffIcon), id: "owner-tariff", separator: false, route: "/owner/tariff" },
  { mobile: false, name: 'Транзакции', icon: markRaw(TransactionIcon), id: "owner-transaction", separator: false, route: "/owner/transaction" },
  { mobile: false, name: 'Промокоды', icon: markRaw(PromocodeIcon), id: "owner-promocode", separator: false, route: "/owner/promocode" },
  { mobile: false, name: 'Рефералка', icon: markRaw(ReferralIcon), id: "owner-referral", separator: false, route: "/owner/referral" },
  { mobile: false, name: 'Партнёрка', icon: markRaw(PartnerIcon), id: "owner-partner", separator: false, route: "/owner/partner" },
  { mobile: false, name: 'Поддержка', icon: markRaw(SupportSidebarIcon), id: "owner-support", separator: false, route: "/owner/support" },
  { mobile: false, name: 'Реклама', icon: markRaw(AdvertisingIcon), id: "owner-advertising", separator: false, route: "/owner/advertising" },
  { mobile: false, name: 'Заказы', icon: markRaw(OrderIcon), id: "owner-order", separator: false, route: "/owner/order" },
  { mobile: true, name: 'Еще', icon: markRaw(MoreIcon), id: "user-more", separator: false, route: "/referral" },
] as SIDEBAR[];