import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-38c5ca18"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "profile flex items-center" }
const _hoisted_2 = { class: "main flex items-center" }
const _hoisted_3 = { class: "email" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_gear_icon = _resolveComponent("gear-icon")!
  const _component_icon_button = _resolveComponent("icon-button")!
  const _component_logout_icon = _resolveComponent("logout-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.user.email), 1),
      _createVNode(_component_icon_button, { type: "small" }, {
        default: _withCtx(() => [
          _createVNode(_component_gear_icon)
        ]),
        _: 1
      }),
      _createVNode(_component_icon_button, {
        type: "small",
        onClick: _ctx.logoutAction
      }, {
        default: _withCtx(() => [
          _createVNode(_component_logout_icon)
        ]),
        _: 1
      }, 8, ["onClick"])
    ])
  ]))
}