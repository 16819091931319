import { dynamicsObject } from "@/interfaces"
import { Modal } from "@/interfaces/modal/modal.dto"

export const advertising = (data: dynamicsObject): Array<Modal.Module> => {
  return [
    {
      id: 'advertising-create',
      data: {
        name: '',
        price: '',
        active: true,
      },
      inputs: [
        { required: true, name: 'Имя партнера *', placeholder: 'Введи имя партнера', id: 'name', grid: '1 / 13', type: 'text', drop: false, showDrop: false, drop_data: [], show: true },
        { required: true, name: 'Бонус, ₽ *', placeholder: 'Введи бонус, который получит пользователь', id: 'price', grid: '1 / 13', type: 'text', mask: '#######', drop: false, showDrop: false, drop_data: [], show: true },
        { required: false, name: '', placeholder: 'Активная ссылка', id: 'active', grid: '1 / 13', type: 'switcher', drop: false, showDrop: false, drop_data: [], show: true },
      ]
    },
    {
      id: 'advertising-edit',
      data: {
        _id: data?._id,
        name: data?.name,
        price: data?.price,
        active: data?.active,
      },
      inputs: [
        { required: true, name: 'Имя партнера *', placeholder: 'Введи имя партнера', id: 'name', grid: '1 / 13', type: 'text', drop: false, showDrop: false, drop_data: [], show: true },
        { required: true, name: 'Бонус, ₽ *', placeholder: 'Введи бонус, который получит пользователь', id: 'price', grid: '1 / 13', type: 'text', mask: '#######', drop: false, showDrop: false, drop_data: [], show: true },
        { required: false, name: '', placeholder: 'Активная ссылка', id: 'active', grid: '1 / 13', type: 'switcher', drop: false, showDrop: false, drop_data: [], show: true },
      ]
    },
    {
      id: 'advertising-delete',
      data: {
        _id: data?._id,
        name: data?.name,
      },
      inputs: []
    },
  ]
}