import { PaymentDetails } from "@/interfaces/payment/payment.dto";

export const currentUserName = (name: string) => {
  const current = name.split(' ');
  return `${current[1]} ${current[0]}`;
}

export const currentUserFirstName = (name: string) => {
  const current = name.split(' ');
  return current[1][0];
}

export const currentFirstName = (name: string) => {
  return name[0];
}

export const onlineStatus = (lastOnline: Date) => {
  return Number(new Date()) - Number(new Date(lastOnline)) < 1000 * 60 * 5;
}

export const correctUserName = (name: string) => {
  const current = name.split(' ');
  return current[1];
};

export const isEmptyUserPayment = (payment: PaymentDetails.Dto) => {
  return !((!!payment.yoomoney?.purse && !!payment.yoomoney?.secretKey) || 
           (!!payment.tinkoff?.terminal && !!payment.tinkoff?.password) || 
           (!!payment.botpay?.card && !!payment.botpay?.expired));
}