import { Modal } from "@/interfaces/modal/modal.dto";

export const user: Array<Modal.Content> = [
  {
    id: 'user-replenishment',
    title: 'Пополнение баланса',
    action: 'edit',
    request: '/api/user/replenishment',
    method: 'post',
    emit: 'user-replenishment',
    cancelName: 'Отмена',
    submitName: 'Пополнить',
  },
  {
    id: 'botpay-withdrawal',
    title: 'Вывод средств',
    action: 'edit',
    request: '/api/payment/botpay/withdrawal',
    emit: 'botpay-withdraw',
    method: 'post',
    cancelName: 'Отмена',
    submitName: 'Вывести',
  },
  {
    id: 'user-partner-withdrawal',
    title: 'Вывод партнерских средств',
    action: 'edit',
    request: '/api/user/withdrawal/partner',
    emit: 'user-partner-withdraw',
    method: 'post',
    cancelName: 'Отмена',
    submitName: 'Вывести',
  },
  {
    id: 'instruction',
    title: 'Инструкция на подключение',
    action: 'static',
    cancelName: 'Отмена',
    submitName: 'Готово',
  },
];