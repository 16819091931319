import { dynamicsObject } from "@/interfaces"
import { Modal } from "@/interfaces/modal/modal.dto"

export const order = (data: dynamicsObject): Array<Modal.Module> => {
  return [
    {
      id: 'order-edit',
      data: {
        _id: data?._id,
      },
      inputs: []
    },
  ]
}