
import { defineComponent, PropType } from "vue-demi";
import { User } from "@/interfaces/user/user.dto";
import { AutopostIcon } from "@/plugins/icons";
import { SIDEBAR, SIDEBAR_OWNER } from "@/store/common/default/navigation";
import { ROLES } from "@/utils/enums";

export default defineComponent({
  name: 'CommonMobileSidebar',
  props: {
    user: {
      type: Object as PropType<User.Dto>,
      required: true
    }
  },
  data() {
    return {
      showMore: false,
    }
  },
  computed: {
    currentMobileSidebar() {
      if (this.user.role === ROLES.OWNER) return SIDEBAR_OWNER.filter(route => route.mobile);
      return SIDEBAR.filter(route => route.mobile);
    },
    currentMoreSidebar() {
      if (this.user.role === ROLES.OWNER) return SIDEBAR_OWNER.filter(route => !route.mobile);
      return SIDEBAR.filter(route => !route.mobile);
    }
  },
  methods: {
    isSectionRoute(element: typeof SIDEBAR[number]) {
      return element.id.includes(this.$route.meta.section as string);
    },
    navigate(element: typeof SIDEBAR[number]) {
      if (element.id === 'user-more') return this.toggleShow();
      this.showMore = false;
      this.$router.push(element.route);
    },
    toggleShow() {
      this.showMore = !this.showMore;
      if (this.showMore) {
        this.jq("body").bind("click", (e) => {
          if (this.jq(e.target).closest('.sidebar').length === 0)
            this.showMore = false;
        });
      } else this.jq('body').unbind('click');
    },
  }
})
