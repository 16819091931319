
import { defineComponent, PropType } from "vue-demi";
import { CalendarDates } from "@/interfaces/common/calendar";
import { getMonthList } from "@/utils/date/calendar";
import { MONTHS_NAMES_CIR } from "@/store/common/default/dates";
import { getDateNullTime } from "@/utils/date";

export default defineComponent({
  emits: ['selectMonth'],
  name: 'CalendarMonths',
  props: {
    dates: {
      type: Object as PropType<CalendarDates>,
      required: true
    },
    isSingleType: {
      type: Boolean,
      required: true
    },
    currentMonth: {
      type: Date,
      required: true
    }
  },
  computed: {
    months() {
      return getMonthList(this.currentMonth)
    }
  },
  methods: {
    currentMonthName(month: number) {
      return MONTHS_NAMES_CIR[month];
    },
    isSeletedMonth(date: Date) {
      return Number(date) === Number(getDateNullTime(this.dates.startedAt as Date, 'month')) || 
             Number(date) === Number(getDateNullTime(this.dates.endedAt as Date, 'month'))
    },
    isBetweenMonth(date: Date) {
      if (!this.dates.startedAt || !this.dates.endedAt) return false;
      if (this.dates.startedAt.getMonth() < date.getMonth() && this.dates.endedAt.getMonth() > date.getMonth()) return true;
      return false;
    },
  }
})
