import { dynamicsObject } from "@/interfaces"
import { Modal } from "@/interfaces/modal/modal.dto"

export const tariff = (data: dynamicsObject): Array<Modal.Module> => {
  return [
    {
      id: 'tariff-create',
      data: {
        name: '',
        channel: data?._id || '',
        channel_id: data?._id || '',
        duration: '',
        price: '',
        visibility: true,
        disposable: false,
        switcher: false,
        referral__status: false,
        referral__days: '',
        referral__link: '',
        referral__reusable: false,
      },
      inputs: [
        { required: true, name: 'Название тарифа *', placeholder: 'Введи название', id: 'name', grid: '1 / 13', type: 'text', drop: false, showDrop: false, drop_data: [], show: true },
        { required: true, name: 'Период оплаты *', placeholder: 'Введи кол-во дней', id: 'duration', grid: '1 / 7', mask: '#####', type: 'text', drop: false, showDrop: false, drop_data: [], show: true },
        { required: true, name: 'Стоимость, ₽ *', placeholder: 'Введи стоимость', id: 'price', grid: '7 / 13', mask: '#######', type: 'text', drop: false, showDrop: false, drop_data: [], show: true },
        { required: true, name: 'Сообщество *', placeholder: 'Выбери сообщество', id: 'channel', grid: '1 / 13', type: 'text', drop: true, showDrop: false, drop_data: [], show: !data?._id },
        { required: false, show: true, name: '', placeholder: 'Одноразовый', id: 'disposable', grid: '1 / 7', type: 'checkbox', drop: false, showDrop: false, drop_data: []},
        { required: false, show: true, name: '', placeholder: 'Отображать в боте', id: 'visibility', grid: '7 / 13', type: 'checkbox', drop: false, showDrop: false, drop_data: []},
        { required: false, name: '', placeholder: 'Реферальная программа', id: 'referral__status', grid: '1 / 13', type: 'switcher', drop: false, showDrop: false, drop_data: [], show: true },
        { referral__status: true, required: false, show: false, name: 'Дней рефералки *', placeholder: 'Введи кол-во дней', id: 'referral__days', grid: '1 / 13', type: 'text', mask: '####', drop: false, showDrop: false, drop_data: []},
        { referral__status: true, required: false, show: false, name: 'Текст над реферальной ссылкой', placeholder: 'Введи текст', id: 'referral__link', grid: '1 / 13', type: 'text', drop: false, showDrop: false, drop_data: []},
        { referral__status: true, required: false, show: false, name: '', placeholder: 'Многоразовая ссылка', id: 'referral__reusable', grid: '1 / 13', type: 'switcher', drop: false, showDrop: false, drop_data: []},
      ]
    },
    {
      id: 'tariff-edit',
      data: {
        _id: data?._id,
        name: data?.name,
        channel: data?.channel?.name,
        channel_id: data?.channel?._id,
        duration: data?.duration,
        price: data?.price,
        visibility: data?.visibility,
        disposable: data?.disposable,
        switcher: false,
        referral__status: data?.referral?.status,
        referral__days: data?.referral?.days,
        referral__link: data?.referral?.link,
        referral__reusable: data?.referral?.reusable,
        double_price__status: data?.double_price?.status,
        double_price__price: data?.double_price?.price,
        double_price__name: data?.double_price?.name,
      },
      inputs: [
        { required: true, name: 'Название тарифа *', placeholder: 'Введи название', id: 'name', grid: '1 / 13', type: 'text', drop: false, showDrop: false, drop_data: [], show: true },
        { required: true, name: 'Период оплаты *', placeholder: 'Введи кол-во дней', id: 'duration', grid: '1 / 7', mask: '#####', type: 'text', drop: false, showDrop: false, drop_data: [], show: true },
        { required: true, name: 'Стоимость, ₽ *', placeholder: 'Введи стоимость', id: 'price', grid: '7 / 13', mask: '#######', type: 'text', drop: false, showDrop: false, drop_data: [], show: true },
        { required: true, name: 'Сообщество *', placeholder: 'Выбери сообщество', id: 'channel', grid: '1 / 13', type: 'text', drop: true, showDrop: false, drop_data: [], show: true },
        { required: false, show: true, name: '', placeholder: 'Одноразовый', id: 'disposable', grid: '1 / 7', type: 'checkbox', drop: false, showDrop: false, drop_data: []},
        { required: false, show: true, name: '', placeholder: 'Отображать в боте', id: 'visibility', grid: '7 / 13', type: 'checkbox', drop: false, showDrop: false, drop_data: []},
        { required: false, name: '', placeholder: 'Реферальная программа', id: 'referral__status', grid: '1 / 13', type: 'switcher', drop: false, showDrop: false, drop_data: [], show: true },
        { referral__status: true, required: data?.referral?.status, show: data?.referral?.status, name: 'Дней рефералки *', placeholder: 'Введи кол-во дней', id: 'referral__days', grid: '1 / 13', type: 'text', mask: '####', drop: false, showDrop: false, drop_data: []},
        { referral__status: true, required: false, show: data?.referral?.status, name: 'Текст над реферальной ссылкой', placeholder: 'Введи текст', id: 'referral__link', grid: '1 / 13', type: 'text', drop: false, showDrop: false, drop_data: []},
        { referral__status: true, required: false, show: data?.referral?.status, name: '', placeholder: 'Многоразовая ссылка', id: 'referral__reusable', grid: '1 / 13', type: 'switcher', drop: false, showDrop: false, drop_data: []},
        { required: false, name: '', placeholder: 'Цена для новых подписчиков', id: 'double_price__status', grid: '1 / 13', type: 'switcher', drop: false, showDrop: false, drop_data: [], show: true },
        { double_price__status: true, required: data?.double_price?.status, name: 'Стоимость, ₽ *', show: data?.double_price?.status, placeholder: 'Введи стоимость', id: 'double_price__price', grid: '1 / 13', type: 'text', mask: '#######', drop: false, showDrop: false, drop_data: [] },
        { double_price__status: true, required: false, name: 'Название тарифа', show: data?.double_price?.status, placeholder: 'Введи название', id: 'double_price__name', grid: '1 / 13', type: 'text', drop: false, showDrop: false, drop_data: [] },
      ]
    },
    {
      id: 'tariff-delete',
      data: {
        _id: data?._id,
        name: data?.name,
      },
      inputs: []
    },
    {
      id: 'tariff-payment-update',
      data: {},
      inputs: []
    },
  ]
}