
import { defineComponent, PropType } from "vue-demi";
import { User } from "@/interfaces/user/user.dto";
import { SubscriberIcon, CancelIcon } from '@/plugins/icons';
import { currentImageSize } from '@/utils/files';

export default defineComponent({
  emits: ['cancelDrop'],
  name: 'ProfileDrop',
  props: {
    user: {
      type: Object as PropType<User.Dto>,
      required: true
    }
  },
  data() {
    return {
      sections: {
        main: [
          { name: 'Личные данные', route: '/profile' },
          { name: 'Автопостинг', route: '/autopost' },
          { name: 'Рассылки', route: '/mailing' },
          // { name: 'Модераторы', route: '/moderator' },
        ],
        logout: [
          { name: 'Покинуть аккаунт', route: 'logout' }
        ]
      }
    }
  },
  methods: {
    currentImageSize,
    router(route: string) {
      if (route === 'logout') return this.$store.dispatch('logoutAction');
      this.$router.push(route);
      this.$emit('cancelDrop');
    }
  },
  components: {
    SubscriberIcon,
    CancelIcon
  }
})
