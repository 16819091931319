
import { defineComponent } from "vue-demi";
import spaceFilter from '@/filters/space.filter';

export default defineComponent({
  emits: ['modal'],
  name: 'ProfileBalance',
  props: {
    botpay: {
      type: Object,
      required: true
    },
  },
  methods: {
    spaceFilter,
  },
})
