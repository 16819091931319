import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "common--calendar--container" }
const _hoisted_2 = { class: "months flex wrap-wrap" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.months, (date, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["month cursor", [{ selected: _ctx.isSeletedMonth(date) }, { between: _ctx.isBetweenMonth(date) }]]),
          key: index,
          onClick: ($event: any) => (_ctx.$emit('selectMonth', date))
        }, _toDisplayString(_ctx.currentMonthName(date.getMonth())), 11, _hoisted_3))
      }), 128))
    ])
  ]))
}