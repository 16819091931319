import { dynamicsObject } from "@/interfaces"
import { Modal } from "@/interfaces/modal/modal.dto"

export const autopost = (data: dynamicsObject): Array<Modal.Module> => {
  return [
    {
      id: 'autopost-create',
      data: {
        channel: '',
        publishAt: '',
        name: '',
        content: '',
        attachment: [],
        buttons: [],
        active: true,
      },
      inputs: [
        { required: true, name: 'Название поста *', placeholder: 'Введи название поста', id: 'name', grid: '1 / 13', type: 'text', drop: false, showDrop: false, drop_data: [], show: true },
        { required: true, name: 'Когда опубликовать *', placeholder: 'Выбери дату публикации', id: 'publishAt', grid: '1 / 13', type: 'datetime', drop: false, showDrop: false, drop_data: [], show: true },
        { required: true, name: 'Сообщество *', placeholder: 'Выбери сообщество', id: 'channel', grid: '1 / 13', type: 'text', drop: true, showDrop: false, drop_data: [], show: true },
        { required: true, name: 'Текст поста *', placeholder: 'Введи текст', id: 'content', grid: '1 / 13', type: 'textarea', drop: false, showDrop: false, drop_data: [], show: true },
        { required: false, name: 'Кнопки', placeholder: '', id: 'buttons', grid: '1 / 13', type: 'buttons', drop: false, showDrop: false, drop_data: [], show: true },
        { required: false, name: 'Вложения', placeholder: '', id: 'attachment', grid: '1 / 13', type: 'attachment', drop: false, showDrop: false, drop_data: [], show: true },
        { required: false, name: '', placeholder: 'Активный пост', id: 'active', grid: '1 / 13', type: 'switcher', drop: false, showDrop: false, drop_data: [], show: true },
      ]
    },
    {
      id: 'autopost-edit',
      data: {
        _id: data?._id,
        channel: data?.channel?.name,
        channel_id: data?.channel?._id,
        publishAt: data?.publishAt,
        name: data?.name,
        content: data?.content,
        attachment: data?.attachment || [],
        buttons: data?.buttons || [],
        active: data?.active,
      },
      inputs: [
        { required: true, name: 'Название поста *', placeholder: 'Введи название поста', id: 'name', grid: '1 / 13', type: 'text', drop: false, showDrop: false, drop_data: [], show: true },
        { required: true, name: 'Когда опубликовать *', placeholder: 'Выбери дату публикации', id: 'publishAt', grid: '1 / 13', type: 'datetime', drop: false, showDrop: false, drop_data: [], show: true },
        { required: true, name: 'Сообщество *', placeholder: 'Выбери сообщество', id: 'channel', grid: '1 / 13', type: 'text', drop: true, showDrop: false, drop_data: [], show: true },
        { required: true, name: 'Текст поста *', placeholder: 'Введи текст', id: 'content', grid: '1 / 13', type: 'textarea', drop: false, showDrop: false, drop_data: [], show: true },
        { required: false, name: 'Кнопки', placeholder: '', id: 'buttons', grid: '1 / 13', type: 'buttons', drop: false, showDrop: false, drop_data: [], show: true },
        { required: false, name: 'Вложения', placeholder: '', id: 'attachment', grid: '1 / 13', type: 'attachment', drop: false, showDrop: false, drop_data: [], show: true },
        { required: false, name: '', placeholder: 'Активный пост', id: 'active', grid: '1 / 13', type: 'switcher', drop: false, showDrop: false, drop_data: [], show: true },
      ]
    },
    {
      id: 'autopost-delete',
      data: {
        _id: data?._id,
        name: data?.name,
      },
      inputs: []
    },
  ]
}