
import { defineComponent, PropType } from "vue-demi";
import { User } from "@/interfaces/user/user.dto";
import { LeftArrowIcon } from '@/plugins/icons';
import { ROLES } from "@/utils/enums";
import IconButton from '@/components/common/button/IconButton.vue';
import Profile from "./Profile.vue";
import ProfileOwner from "./ProfileOwner.vue";

export default defineComponent({
  name: 'Header',
  props: {
    user: {
      type: Object as PropType<User.Dto>,
      required: true
    },
  },
  computed: {
    isNewChannel() {
      return this.$route.meta.id === 'channel-list' && this.user.role === ROLES.USER;
    },
    currentRouteName() {
      return this.$route.meta.title;
    },
    isBackRoute() {      
      return !!this.$route.meta.back;
    },
    isBackName() {
      return !this.$route.meta.back || this.$route.meta.backName;
    },
    isDesktop() {
      return this.$store.getters.innerWidth > 600;
    },
    isUser() {
      return this.user.role === ROLES.USER;
    }
  },
  methods: {
    backRoute() {
      if (this.$route.meta.id === 'channel-settings')
        return this.$router.push(`/channel/${this.$route.params._id}`);
      this.$router.push(this.$route.meta.back as string);
    }
  },
  components: {
    Profile,
    ProfileOwner,
    IconButton,
    LeftArrowIcon
  }
})
