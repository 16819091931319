
import { defineComponent, PropType, defineAsyncComponent } from "vue-demi";
import { mapGetters } from "vuex";
import { AxiosRequestConfig } from "axios";

import { Modal } from "@/interfaces/modal/modal.dto";
import { Table } from "@/interfaces/table/table.dto";

import getModalContent from './datas/content';
import getModalInputs from './datas/inputs';
import getModalRequests from './datas/requests';
import { useAPI } from "@/use";
import { commonCheckInputs } from '@/store/common/default/inputs'; 
import { Mailing } from "@/interfaces/mailing/mailing.dto";
import { dynamicsObject } from "@/interfaces";

const ModalHeader = defineAsyncComponent(() => import('./modules/header/index.vue'))
const ModalContainer = defineAsyncComponent(() => import('./modules/container/index.vue'))
const ModalActions = defineAsyncComponent(() => import('./modules/actions/index.vue'))

export default defineComponent({
  emits: ['showModal'],
  name: 'ModalMain',
  props: {
    modal: {
      type: Object as PropType<Table.Main>,
      required: true
    }
  },
  data() {
    return {
      options: {} as Table.Main,
      data: {} as Modal.Main,
      datas: [],
    }
  },
  created() {
    this.options = this.modal;
    this.data = {
      content: getModalContent(this.options),
      inputs: getModalInputs(this.options),
    };
    getModalRequests(this.data);
  },
  mounted() {
    document.addEventListener('keydown', this.keys);
  },
  unmounted() {
    document.removeEventListener('keydown', this.keys);
  },
  computed: {
    ...mapGetters(['pendingRequest']),
    isOverflow() {
      return ['channel-referral', 'channel-quiz', 'mailing-create', 'mailing-edit', 'tariff-create', 'tariff-edit', 'channel-tariff', 'autopost-create', 'autopost-edit'].includes(this.modal.id);
    }
  },
  methods: {
    keys(ev: KeyboardEvent) {
      if (ev.key === 'Escape' && !this.$store.getters.drop) this.removeModal();
      if (ev.key === 'Enter' && !ev.shiftKey && !this.$store.getters.calendar && this.data.content.action !== 'static') this.submit();
    },
    async submit() {
      this.$store.commit('togglePendingRequest', true);
      const resultCheck = commonCheckInputs(this.data.inputs.inputs, this.data.inputs.data);
      if (!resultCheck.status && resultCheck.showMessage) {
        this.notification(`Вам необходимо выбрать вариант из списка в поле "${resultCheck.input.name}"!`, 'error')
      }
      console.log(resultCheck);
      
      if (!resultCheck.status) {
        return this.$store.commit('togglePendingRequest', false);
      }
      this.manipulationOptions();
      const newId = await this.request();

      if (this.data.content.fileRequest && this.data.inputs.data.file) {
        await this.updateFile(newId);
      }
      if (this.data.content.fileRequest && this.data.inputs.data.files) {
        await this.updateFiles(newId);
      }
      this.removeModal();
    },
    async request() {
      const options = this.collectOptions();      
      const result = await useAPI().common.modal.onSubmit(options);

      if (this.data.content.emit) {
        const modal = this.data.content.id.split('-');
        this.emitter.emit(this.data.content.emit, { data: result.data, action: modal.pop() });
      }
      if (result.data?.type === 'payment') location.replace(result.data?.url);

      return this.data.content.action === 'add' ? result.data._id : '';
    },
    async updateFile(_id: string) {
      const formData = new FormData();
      formData.append("file", this.data.inputs.data.file);
      formData.append("_id", _id || this.data.inputs.data._id);

      const options = this.collectFileOptions(formData, _id);        
      const resultFile = await useAPI().common.modal.uploadFileMethod(options);
      this.emitter.emit(this.data.content.fileEmit || this.data.content.emit, resultFile.data);
    },
    async updateFiles(_id: string) {
      const formData = new FormData();
      formData.append("_id", _id || this.data.inputs.data._id);
      for (const file of this.data.inputs.data.files) {
        formData.append("newAttachment", file);
      }

      const options = this.collectFileOptions(formData, _id);   
      const resultFile = await useAPI().common.modal.uploadFileMethod(options);
      this.emitter.emit(this.data.content.fileEmit || this.data.content.emit, resultFile.data);
    },
    manipulationOptions() {
      if (['mailing-create', 'mailing-edit'].includes(this.data.content.id)) {
        const data = this.data.inputs.data as dynamicsObject;
        data.statuses = data.statuses.filter((s: { selected: boolean }) => s.selected).map((s: { _id: string }) => s._id);
        data.subscribers = data.subscribers.filter((s: { selected: boolean }) => s.selected).map((s: { _id: string }) => s._id);
      }
    },
    collectOptions() {
      const options: AxiosRequestConfig = {
        method: this.data.content.method,
        url: `${this.data.content.request}${
          this.data.content.method === "delete"
            ? `/${this.data.inputs.data._id}`
            : ""
        }`,
      }
      if (this.data.content.method !== "delete") 
        options.data = this.data.inputs.data;
      return options;
    },
    collectFileOptions(formData: FormData, _id: string) {
      const options: AxiosRequestConfig = {
        method: this.data.content.fileMethod,
        url: `${this.data.content.fileRequest}${
          this.data.content.fileMethod === "delete"
            ? `/${_id || this.data.inputs.data._id}`
            : ""
        }`,
        headers: { "Content-Type": "multipart/form-data" }
      }
      if (this.data.content.fileMethod !== "delete") {
        options.data = formData;
        options.params = { _id: _id || this.data.inputs.data._id }
      }
      return options;
    },
    notification(message: string, status: string) {
      this.$store.commit("createNotification", { status, message });
    },
    removeModal() {
      this.$store.commit('destroyModal');
    },
  },
  components: {
    ModalHeader,
    ModalContainer,
    ModalActions
  }
})
