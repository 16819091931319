
import { defineComponent, PropType } from "vue-demi";
import { User } from "@/interfaces/user/user.dto";
import { currentImageSize } from "@/utils/files";
import { SubscriberIcon, DownArrowIcon } from "@/plugins/icons";
import Balance from "./Balance.vue";
import BalanceBotpay from "./BalanceBotpay.vue";
import Drop from "./Drop.vue";
import MobileDrop from "./mobile/Drop.vue";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "HeaderProfile",
  props: {
    user: {
      type: Object as PropType<User.Dto>,
      required: true,
    },
    isDesktop: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  created() {
    this.$store.dispatch("getBotpayPaymentMethod");
  },
  methods: {
    currentImageSize,
    modal(id: string) {
      this.$store.commit("createModal", { id, data: {} });
    },
    toggleShow() {
      this.show = !this.show;
      if (this.show) {
        this.jq("body").bind("click", (e) => {
          if (
            this.jq(e.target).closest(
              ".profile .cursor, .profile--drop, .mobile-drop"
            ).length === 0
          )
            this.show = false;
        });
      } else this.unbindDrop();
    },
    unbindDrop() {
      this.jq("body").unbind("click");
    },
    cancelDrop() {
      this.show = false;
      this.unbindDrop();
    },
  },
  computed: {
    ...mapGetters(["botpay"]),
    currentBalance() {
      return this.user.balance;
    },
  },
  components: {
    BalanceBotpay,
    SubscriberIcon,
    DownArrowIcon,
    Balance,
    Drop,
    MobileDrop,
  },
});
