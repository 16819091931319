import { Modal } from "@/interfaces/modal/modal.dto";

export const promocode: Array<Modal.Content> = [
  {
    id: 'promocode-create',
    title: 'Сгенерировать промокод',
    action: 'add',
    request: '/api/promocode',
    method: 'post',
    cancelName: 'Отмена',
    submitName: 'Сгенерировать',
    emit: 'table-action',
  },
  {
    id: 'promocode-view',
    title: 'Сгенерированный промокод',
    action: 'static',
    cancelName: 'Отмена',
    submitName: 'Готово',
  },
  {
    id: 'promocode-delete',
    title: '',
    action: 'delete',
    request: '/api/promocode',
    method: 'delete',
    cancelName: 'Отмена',
    submitName: 'Удалить',
    message: 'удалить этот промокод',
    emit: 'table-action',
  },
];