
import { defineComponent, PropType } from "vue-demi";
import { dayWeekList } from "@/utils/date/calendar";
import { getDateNullTime } from "@/utils/date";
import { CalendarDates } from "@/interfaces/common/calendar";
import { getWeeks } from '@/utils/date/calendar';

export default defineComponent({
  emits: ['selectedDate'],
  name: 'ContainerDates',
  props: {
    dates: {
      type: Object as PropType<CalendarDates>,
      required: true
    },
    isSingleType: {
      type: Boolean,
      required: true
    },
    currentMonth: {
      type: Date,
      required: true
    }
  },
  data() {
    return {
      dayweek: dayWeekList(),
    }
  },
  methods: {
    isSeletedDate(date: Date) {
      return Number(date) === Number(getDateNullTime(this.dates.startedAt as Date)) || Number(date) === Number(getDateNullTime(this.dates.endedAt as Date))
    },
    isBetweenDate(date: Date) {
      if (!this.dates.startedAt || !this.dates.endedAt) return false;
      if (Number(getDateNullTime(this.dates.startedAt as Date)) < Number(date) && Number(getDateNullTime(this.dates.endedAt as Date)) > Number(date)) return true;
      return false;
    },
    selectDate(date: { value: Date }) {
      if (this.isSingleType) return this.selectSingleType(date);
      this.selectSeveralType(date);
    },
    selectSingleType(date: { value: Date }) {
      this.dates.startedAt = date.value;
      this.$emit('selectedDate', this.dates);
    },
    selectSeveralType(date: { value: Date }) {
      if (this.dates.startedAt && this.dates.endedAt) {
        this.dates.startedAt = date.value;
        this.dates.endedAt = null;
      } else if (this.dates.startedAt) {
        if (Number(date.value) < Number(this.dates.startedAt)) {
          this.dates.endedAt = this.dates.startedAt; 
          this.dates.startedAt = date.value;
        } else {
          this.dates.endedAt = date.value;
        }
      } else if (!this.dates.startedAt) {
        this.dates.startedAt = date.value;
      }
      this.$emit('selectedDate', this.dates);
    },
    getWeeks,
  }
})
